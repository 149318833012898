.sectionAbout-container {
    position: relative;
    width: 100vw;
    height: 100%;
    padding-top: 20rem;
    transition: background-color 1s ease-in-out;
}

.sectionAbout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 200vh;
    gap: 10rem;
}

.sectionAbout-text {
    width: 60%;
    opacity: 0;
    transform: translateX(-15%);
    transition: all 1.5s ease-in-out;
}

.sectionAbout-text.sectionAbout-text-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1.5s ease-in-out;
}

.sectionAbout-text p {
    font-size: 3rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-align: center;
}

.sectionAbout-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
}

.sectionAbout-body-image {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 60vw;

}

.sectionAbout-body-image span {
    display: block;
    background: url(../../../assets/sectionAbout/1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20%;
    width: 0%;
    transition: width 1s ease-in-out;

}

.sectionAbout-body-image-active {
    width: 100% !important;
    transition: width 1s ease-in-out;
}

@keyframes imageWidth {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}


.sectionAbout-body-image span:nth-child(1){
    background-position: 0 -15vh;
    transition-delay: .1s;
}

.sectionAbout-body-image span:nth-child(2){
    background-position: 0 -31vh;
    transition-delay: .3s;
}

.sectionAbout-body-image span:nth-child(3){
    background-position: 0 -47vh;
    transition-delay: .5s;
}

.sectionAbout-body-image span:nth-child(4){
    background-position: 0 -63vh;
    transition-delay: .7s;
}

.sectionAbout-body-image span:nth-child(5){
    background-position: 0 -79vh;
    transition-delay: .9s;
}

.sectionAbout-body-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: 30%;
    transform: translateX(35%);
    opacity: 0;
    transition: all 1.5s ease-in-out;
}

.sectionAbout-body-text.sectionAbout-body-text-active {
    transform: translateX(50%);
    opacity: 1;
    transition: all 1.5s ease-in-out;
}

.sectionAbout-body-text p {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: left;
}

@media screen and (max-width: 900px) {
    .sectionAbout-container {
        margin-top: 50rem;
    } 
    .sectionAbout-content {
        height: 180vh;
    }

    .sectionAbout-text {
        width: 90%;
    }

    .sectionAbout-text p {
        font-size: 2rem;
    }

    .sectionAbout-body {
        gap: 3rem;
    }

    .sectionAbout-body-image {
        height: 60vh;
        width: 80vw;
    }

    .sectionAbout-body-text {
        width: 85%;
        transform: translateX(0%);
    }

    .sectionAbout-body-text.sectionAbout-body-text-active {
        transform: translateX(5%);
    }
}

@media screen and (max-width: 600px){
    .sectionAbout-container {
        margin-top: 60rem;
    }

    .sectionAbout-content {
        height: 200vh;
    }

    .sectionAbout-text {
        width: 90%;
    }

    .sectionAbout-text p {
        font-size: 1.6rem;
    }

    .sectionAbout-body {
        gap: 2rem;
    }

    .sectionAbout-body-image {
        height: 50vh;
        width: 90vw;
    }

    .sectionAbout-body-text {
        width: 90%;
        transform: translateX(0%);
    }

    .sectionAbout-body-text.sectionAbout-body-text-active {
        transform: translateX(5%);
    }
}

@media screen and (max-width: 400px){
    .sectionAbout-container {
        margin-top: 70rem;
    }

    .sectionAbout-content {
        height: 220vh;
    }

    .sectionAbout-text {
        width: 90%;
    }

    .sectionAbout-text p {
        font-size: 1.4rem;
    }

    .sectionAbout-body {
        gap: 1rem;
    }

    .sectionAbout-body-image {
        height: 80vh;
        width: 90vw;
    }

    .sectionAbout-body-text {
        width: 90%;
        transform: translateX(0%);
    }

    .sectionAbout-body-text.sectionAbout-body-text-active {
        transform: translateX(5%);
    }

}

@media screen and (max-width: 320px){
    .sectionAbout-container {
        margin-top: 80rem;
    }

    .sectionAbout-content {
        height: 240vh;
    }

    .sectionAbout-text {
        width: 90%;
    }

    .sectionAbout-text p {
        font-size: 1.2rem;
    }

    .sectionAbout-body {
        gap: 1rem;
    }

    .sectionAbout-body-image {
        height: 30vh;
        width: 90vw;
    }

    .sectionAbout-body-text {
        width: 90%;
        transform: translateX(0%);
    }

    .sectionAbout-body-text.sectionAbout-body-text-active {
        transform: translateX(5%);
    }
}

