.langSelection-container {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 9999;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}

.langSelection-active {
  display: flex;
}

.langSelection-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.langSelection-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
}

.langSelection-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 3rem;
    cursor: pointer;
    transition: all .3s;
    outline: none;
    border-radius: 1rem;
    background-color: transparent;
    border: none;
}

.langSelection-button img {
    width: 8rem;
    height: auto;
    border-radius: 50%;
}

.langSelection-button h1 {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: none;
}
