.line-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.line-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15vw;
}

.line-right-group, .line-left-group {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

.line {
    position: relative;
    width: 1px;
    height: 100%;
}

.line::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 60px;
    animation: pxl_grid_fall 25s linear infinite;
}

.line-container.darkMode .line {
    background-color: #ffffff0f;
    mix-blend-mode: difference;
}

.line-container.darkMode .line::after {
    background: #fff;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.00000001)));
    background: -o-linear-gradient(bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.00000001) 100%);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.00000001) 100%);
}

.line-container.lightMode .line {
    background-color: #0000000f;
    mix-blend-mode: difference;
}

.line-container.lightMode .line::after {
    background: #000;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.00000001)));
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.00000001) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.00000001) 100%);
}



@keyframes pxl_grid_fall {
    0% {
        top: 0;
    }

    10%{
        top: 100%;
    }
    100% {
        top: 100%;
    }
}


.line:nth-child(1)::after {
    animation-duration: 50s;
}
.line:nth-child(2)::after {
    animation-duration: 33s;
}
.line:nth-child(3)::after {
    animation-duration: 25s;
}
.line:nth-child(4)::after {
    animation-duration: 40s;
}
.line:nth-child(5)::after {
    animation-duration: 28s;
}
.line:nth-child(6)::after {
    animation-duration: 50s;
}

@media screen and (max-width: 900px) {
    .line-content {
        gap: 10vw;
    }

}


