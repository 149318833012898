@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-font: "Poppins", sans-serif;
    --secondary-font: 'Great Vibes', cursive;
    --primary-color: #131313;
    --font-color: #fff;
}


html {
    font-size: 10px;
}

body {
    font-size: 1.6rem;
    line-height: 1.6;
    font-family: var(--main-font);
}

body.darkMode {
    background-color: #131313;
    color: #fff;
    transition: all 1s ease-in-out;
}

body.lightMode {
    background-color: #fff;
    color: #000;
    transition: all 1s ease-in-out;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
}


