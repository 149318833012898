.slider-container {
    position: relative;
    width: 100vw;
    height: 80vh;
    margin-top: 15rem;

}

.slider-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.slider {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slider-item {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%) scale(0.8);
    opacity: 0;
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.slider-item.slider-item-active {
    transform: translateX(0%) scale(1);
    opacity: 1;
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.slider-item.slider-item-next {
    transform: translateX(100%) scale(0.8);
    opacity: 0;
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.slider-item img {
    width: 70%;
    height: 100%;
    object-fit: cover;
}

.slider-item-info {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 12vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 2rem;
}

.slider-item-info.lightMode {
    background-color: #000000;
}

.slider-item-info.darkMode {
    background-color: #181717;
}

.slider-item-info h1 {
    font-size: 2rem;
    font-weight: 200;
    color: #fff;
    text-transform: uppercase;
}

.slider-item-info span {
    width: 1px;
    height: 20%;
    background-color: #ffffff54;
}

.slider-item-info p {
    font-size: 1.3rem;
    font-weight: 200;
    color: #fff;
    margin-top: 1rem;
    text-align: left;
}

.slider-item-info a {
    font-size: 1.5rem;
    font-weight: 200;
    color: #A6A182;
    margin-top: 1rem;
    text-align: left;
}

@media screen and (max-width: 1640px){
    .slider-container {
        position: relative;
        width: 100vw;
        height: 80vh;
        margin-top: 12rem;
    }

    .slider-item-info h1 {
        font-size: 1.7rem;
    }

    .slider-item-info p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1440px){
    .slider-container {
        position: relative;
        width: 100vw;
        height: 80vh;
        margin-top: 10rem;
    }

    .slider-item-info {
        width: 15vw;
    }

    .slider-item-info h1 {
        font-size: 1.7rem;
    }

    .slider-item-info p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 900px) {
    .slider-container {
        position: relative;
        width: 100vw;
        height: 80vh;
        margin-top: 10rem;
    }

    .slider-item img {
        width: 100%;
        height: 100%;
    }

    .slider-content {
        padding: 2rem 2rem;
    }

    .slider-item-info {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .slider-container {
        position: relative;
        width: 100vw;
        height: 70vh;
        margin-top: 15rem;
    }

    .slider-content {
        padding: 2rem 2rem;
    }

    .slider-item img {
        width: 100%;
        height: 100%;
    }

    .slider-item-info {
        display: none;
    }

}