.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 99;
    background-color: transparent;
}


.header-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3rem 4rem;
    width: 80%;
    height: 100%;
    margin: 0rem auto;
    transform: translateY(-100%);
}

.header-content.darkMode {
    background-color: #131313;
    color: #fff;
    transition: all 1s ease-in-out;
}

.header-content.lightMode {
    background-color: #fff;
    color: #131313;
    transition: all 1s ease-in-out;
}

.header-content.header-active {
    transform: translateY(0) !important;
    transition: all 1s ease-in-out;
}

.header-logo h1 {
    font-size: 3rem;
    font-weight: 200;
    letter-spacing: .16em;
    text-transform: uppercase;
    cursor: pointer;
}

.header-logo img {
    width: 15rem;
    height: auto;
}

.header-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.header-menu a {
    position: relative;
    font-size: 1.3rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: .16em;
}

.header-menu a::after {
    content: '';
    position: absolute;
    bottom: -2%;
    left: 0;
    right: 0;
    width: 0;
    height: .1px;
    background-color: #fff;
    transition: width .7s ease;
}

.header-menu a:hover::after{
    content: '';
    position: absolute;
    bottom: -2%;
    left: 0;
    right: 0;
    width: 100%;
    height: .1px;
    background-color: #fff;
    transition: width .5s ease;
}

.header-menu.lightMode a::after {
    content: '';
    position: absolute;
    bottom: -2%;
    left: 0;
    right: 0;
    width: 0;
    height: .1px;
    background-color: #000;
    transition: width .7s ease;
}

.header-menu.lightMode a:hover::after{
    content: '';
    position: absolute;
    bottom: -2%;
    left: 0;
    right: 0;
    width: 100%;
    height: .1px;
    background-color: #000;
    transition: width .5s ease;
}


.header-quick-contact-button img {
    width: 2rem;
    height: auto;
    cursor: pointer;
}

.header-mobile-button {
    display: none;
}

.header-mobile-menu.header-mobile-menu-active {
    display: flex;
}

.header-mobile-button img {
    width: 2rem;
    height: auto;
    cursor: pointer;
}

/* Mobile Header */

.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #000;
    transform: translateX(100%);
    transition: transform .5s cubic-bezier(0.86, 0, 0.07, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;
}

.mobile-header-active {
    transform: translateX(0) !important;
    transition: transform .5s cubic-bezier(0.86, 0, 0.07, 1);
}

.mobile-header-close {
    position: absolute;
    top: 4rem;
    right: 3rem;
    cursor: pointer;
}

.mobile-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 0 3rem;
}

.mobile-header-content.darkMode a {
    color: #fff;
}

.mobile-header-content.lightMode a {
    color: #fff;
}

.mobile-header-content a {
    font-size: 2.5rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: .16em;
}

.mobile-header-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.mobile-header-bottom p {
    font-size: 1.2rem;
    font-weight: 400;
    color: #999999;
    text-align: center;
    padding: 2rem;
}

.language {
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-60%);
    display: flex;
    gap: 1rem;
}

.language button {
    font-size: 1.2rem;
    font-weight: 400;
    color: #999999;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}



/* Quick Contact */

.header-quick-contact {
    position: fixed;
    top: 0;
    right: 0;
    width: 40vw;
    height: 100%;
    z-index: 99;
    background-color: #000;
    transform: translateX(100%);
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
    overflow: hidden;
}


.header-quick-contact.lightMode {
    background-color: #000000;
    color: #acacac;
}

.header-quick-contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/quickHeader/1.jpg);
    background-size: cover;
    background-position: center;
    opacity: .2;
    z-index: -1;
}

.header-quick-contact-active {
    transform: translateX(0) !important;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.header-quick-contact-close {
    position: absolute;
    top: 4rem;
    right: 3rem;
    cursor: pointer;
}

.header-quick-contact-close img {
    width: 2.5rem;
    height: auto;
}

.header-quick-contact-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 3rem;
    width: 50%;
    margin: 0 auto;
}

.header-quick-contact-content p {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    color: #999999;
}

.header-quick-contact-content h3 {
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;
}

.header-quick-contact-info-row-1, .header-quick-contact-info-row-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-top: 2rem;
}

.webMode {
    position: absolute;
    top: 30%;
    right: 2%;
    cursor: pointer;
    border-radius: 30px;
    width: 8rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.webMode.darkMode {
    border: 1px solid #ffffff38;
    background-color: #000;
    transition: all 2s ease-in-out;
}

.webMode.lightMode {
    border: 1px solid #00000038;
    background-color: #fff;
    transition: all 2s ease-in-out;
}

.webMode-swiper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3px;
    margin: 0 auto;
}

.dark, .light {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 2s ease;
}

.webMode-swiper img {
    width: 2rem;
    height: auto;;
}

.swiper-button{
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    border-radius: 100%;
}

.swiper-button.darkMode{
    background-color: #fff;
    left: 10%;
    transition: all 2s ease;
}

.swiper-button.lightMode{
    background-color: #000;
    right: 10%;
    transition: all 2s ease;
}

@media screen and (max-width: 1440px){
    .header-menu a {
        font-size: 1rem;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: .16em;
    }
}

@media screen and (max-width: 1024px){
    .header-menu a {
        font-size: 1rem;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: .16em;
    }
}

@media screen and (max-width: 900px) {
    .header-content {
        padding: 4rem 3rem;
        width: 100%;
        justify-content: space-between;
        margin: 0rem auto;
    }

    .header-menu {
        display: none;
    }

    .header-quick-contact-button {
        display: none;
    }

    .header-mobile-button {
        display: flex;
    }

    .header-mobile-button img {
        width: 2rem;
        height: auto;
        cursor: pointer;
    }

    .header-menu a {
        font-size: 1.3rem;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: .16em;
    }

    .language {
        right: 20%;
    }

    .language button {
        font-size: 1.1rem;
        font-weight: 400;
        color: #999999;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
    }
}

