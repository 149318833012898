.comment-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    overflow: hidden;
    transition: all .5s ease-in-out;
}

.comment-container.lightMode {
    background-color: #fff;
    color: #000;
    transition: all 2s ease-in-out;
}

.sectionComment-container {
    position: relative;
    width: 90%;
    height: 50vh;
    background-color: #000;
    margin: 10rem auto;
    border: 2px solid #131313;
    overflow: hidden;
}


.sectionComment-bg-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 90%;
    background: url(https://demo.bravisthemes.com/mrittik/wp-content/uploads/2023/05/testimonial-bg-01.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.sectionComment-bg-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 15rem;
    background: url(https://demo.bravisthemes.com/mrittik/wp-content/uploads/2023/05/testimonial-bg-02.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.sectionComment-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

}

.sectionComment-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sectionComment-list {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 500rem;
    height: 100%;
    gap: 20rem;
    margin: 0 auto;
}

.sectionComment-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    width: 70rem;
    height: 100%;  
    margin: 0 auto;
    filter: blur(10px);
    transition: all 0.5s ease;
}

.sectionComment-item.lightMode {
    color: #fff;

}

.sectionComment-item.comment-active {
    filter: blur(0);
    transition: all 0.5s ease;
}

.sectionComment-item p {
    font-size: 1.8rem;
    text-align: center;
    margin: 1rem 0;
    line-height: 2;
    font-weight: 300;
}

.sectionComment-item h1 {
    font-size: 1.8rem;
    color: #A6A182;
    text-align: center;
    margin: 1rem 0;
    font-weight: 300;

}

.comment-next-button, .comment-prev-button {
    position: absolute;
    bottom: 10%;
    width: 5rem;
    height: 5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment-next-button {
    right: 45%;
}

.comment-prev-button {
    left: 45%;
}



.comment-next-button img, .comment-prev-button img {
    width: 3rem;
    height: 3rem;
}

.comment-next-button:active, .comment-prev-button:active {
    transform: scale(0.9);
}

@media screen and (max-width: 900px){
    .comment-next-button {
        right: 30%;
    }

    .comment-prev-button {
        left: 30%;
    }

    .comment-next-button img, .comment-prev-button img {
        width: 4rem;
        height: 4rem;
    }
}

@media screen and (max-width: 768px) {
    .sectionComment-container {
        height: 100vh;
    }

    .sectionComment-bg-1 {
        width: 50%;
        height: 50%;
    }

    .sectionComment-bg-2 {
        width: 70%;
        height: 10rem;
    }

    .sectionComment-list {
        gap: 10rem;
    }

    .sectionComment-item {
        width: 30rem;
    }

    .sectionComment-item p {
        font-size: 1.5rem;
    }

    .sectionComment-item h1 {
        font-size: 1.5rem;
    }

    .comment-next-button {
        right: 30%;
    }

    .comment-prev-button {
        left: 30%;
    }

    .comment-next-button img, .comment-prev-button img {
        width: 4rem;
        height: 4rem;
    }
    
}