.sectionContact-container {
    position: relative;
    padding: 10rem;
    background-color: #000000ad;
    width: 100%;
    height: 60vh;
    z-index: -1;
}

.sectionContact-container.lightMode {
    background-color: #fff;
}

.sectionContact-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    gap: 5rem;
}

.sectionContact-title {
    transform: rotate(-90deg);
}

.sectionContact-title h1 {
    font-size: 10rem;
    font-weight: 600;
}

.sectionContact-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 10rem;
}

.sectionContact-info-item {
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.sectionContact-info h3 {
    color: #A6A182;
    font-size: 1.8rem;
    font-weight: 300;
}

.sectionContact-info p {
    font-size: 1.8rem;
    font-weight: 300;
}

.sectionContact-signature{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-left: 50rem;
    transform: translate(10%, 80%);
}

.sectionContact-signature h1 {
    font-size: 4rem;
    font-weight: 300;
}

.sectionContact-signature p {
    font-size: 1.2rem;
    font-weight: 300;
}

@media screen and (max-width: 1640px){
    .sectionContact-signature {
        margin-left: 30rem;
    }
}

@media screen and (max-width: 1440px){
    .sectionContact-signature {
        margin-left: 20rem;
    }
}

@media screen and (max-width: 1280px){
    .sectionContact-signature {
        margin-left: 10rem;
    }
}

@media screen and (max-width: 900px){

    .sectionContact-title {
        transform: rotate(0deg);
    }

    .sectionContact-title h1 {
        font-size: 5rem;
    }
    
    .sectionContact-content {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 5rem;
    }

    .sectionContact-info h3 {
        font-size: 1.5rem;
    }

    .sectionContact-info p {
        font-size: 1.5rem;
    }

    .sectionContact-info {
        margin-left: 0;
    }

    .sectionContact-signature {
        position: absolute;
        bottom: 18%;
        right: 4%;
        margin-left: 0;
        transform: translate(0, 0);
    }

    .sectionContact-signature h1 {
        font-size: 4rem;
    }

    .sectionContact-signature p {
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 600px) {
    .sectionContact-container {
        height: 100vh;
    }
    .sectionContact-title {
        transform: rotate(0deg);
    }

    .sectionContact-title h1 {
        font-size: 5rem;
    }
    
    .sectionContact-content {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 3rem;
    }

    .sectionContact-info h3 {
        font-size: 1.5rem;
    }

    .sectionContact-info p {
        font-size: 1.5rem;
    }

    .sectionContact-info {
        margin-left: 0;
    }

    .sectionContact-signature {
        position: relative;
        right: auto;
        bottom: auto;
        margin-left: 0;
        transform: translate(0, 0);
        margin-top: 3rem;
    }

    .sectionContact-signature h1 {
        font-size: 4rem;
    }

    .sectionContact-signature p {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 400px){
    .sectionContact-title h1 {
        font-size: 3rem;
    }

    .sectionContact-info h3 {
        font-size: 1.2rem;
    }

    .sectionContact-info p {
        font-size: 1.2rem;
    }

    .sectionContact-signature h1 {
        font-size: 3rem;
    }

    .sectionContact-signature p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 320px){

    .sectionContact-container {
        height: 120vh;
    }

    .sectionContact-info h3 {
        font-size: 1rem;
    }

    .sectionContact-info p {
        font-size: 1rem;
    }

    .sectionContact-signature h1 {
        font-size: 2rem;
    }

    .sectionContact-signature p {
        font-size: .8rem;
    }
}

