.projects-container {
    position: relative;
    padding-top: 25rem;
    overflow-x: hidden;
}

.projects-container.lightMode {
    background-color: #fff;
}


.projects-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15rem;
    width: 100%;
    height: 100%;
}

.projects-main-title {
    display: flex;
    justify-content: center;
    position: relative;
    width: 80%;
    height: fit-content;
    margin: 0 auto;
    text-align: center;
    padding: 3rem 0;
    border: 1px solid #ffffff7a;
}

.projects-container.lightMode .projects-main-title {
    border: 1px solid #0000007a;
}

.projects-main-title span h1{
    font-size: 5rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .26em;
}

.projects-main-title span {
    width: fit-content;
    height: fit-content;
    transform: translateY(-100%);
    opacity: 0;
}

.projects-main-title span.projects-main-title-active {
    transform: translateY(0%);
    opacity: 1;
    transition: all 1s ease-in-out;
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.project-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 40%;
    height: 100%;
    transition: all .3s;
    margin-left: 8rem;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 1s ease-in-out;
    z-index: 10;
}

.project-item.project-item-active {
    transform: translateX(0%);
    opacity: 1;
}


.project-item:nth-child(even) {
    justify-self: flex-end;
    margin-left: 0;
    margin-right: 8rem;
    transform: translateX(50%);
    transition: all 1s ease-in-out;
}

.project-item:nth-child(even).project-item-active {
    transform: translateX(0%);
    opacity: 1;
}

.project-item:nth-child(even) .project-text {
    flex-direction: row-reverse;
}

.project-item:nth-child(even) .project-text-left {
    margin-right: 0;
    margin-left: 3rem;
}


.project-image {
    width: 100%;
    height: 100%;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.project-text h3 {
    font-size: 1.4rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .26em;
}

.project-text p {
    font-size: 1rem;
    font-weight: 300;
    text-transform: lowercase;
    letter-spacing: .26em;
    margin-left: 4rem;
}

.project-text-left h3 {
    font-size: 1rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: .26em;
    margin-right: 3rem;
}

.project-item-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all .7s ease;
}

.projects-container.lightMode .project-item-hover {
    background-color: #ffffff7a;
}

.project-item-hover h1 {
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .26em;
}

.project-item:hover .project-item-hover {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .projects-container {
        padding-top: 35rem;
    }

    .projects-main-title {
        font-size: 3rem;
        width: 90%;
        padding: 2rem 0;
    }

    .projects-list {
        width: 90%;
    }

    .project-item {
        width: 80%;
        margin-left: 0;
    }

    .project-item:nth-child(even) {
        margin-right: 0;
        justify-self: flex-end;
    }

    .project-item:nth-child(even) .project-text {
        flex-direction: row;
    }

    .project-item:nth-child(even) .project-text-left {
        justify-self: center;
    }

    .project-text p {
        margin-left: 0;
    }

    .project-item-hover h1 {
        font-size: 1.6rem;
    }

    .project-text h3 {
        font-size: 1.6rem;
    }

    .project-text p {
        display: none;
    }

    .project-text-left h3 {
        font-size: 1.2rem;
    }

}