.singleProject-container {
    position: relative;
    padding-top: 15rem;
}

.singleProject-container.lightMode {
    background-color: #fff;
}


.singleProject-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    gap: 5rem;
}

.singleProject-main-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 80vh;
}

.singleProject-main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.singleProject-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    width: 90%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.singleProject-info h1 {
    font-size: 2.3rem;
    font-weight: 300;
}

.singleProject-info-right h1{
    text-align: right;
}

.singleProject-description {
    width: 60%;
    margin-top: 10rem;
}

.singleProject-description p {
    font-size: 4rem;
    font-weight: 300;
    text-align: center;
}

.singleProject-images-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    gap: 20rem;
    margin-top: 10rem;
    overflow: hidden;
}

.singleProject-images-list img {
    width: 70%;
    height: 70vh;
    object-fit: contain;
    margin-top: 5rem;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 1s ease-in-out;
}

.singleProject-images-list img:nth-child(even){
    justify-self: flex-end;
    transform: translateX(50%);
}

.singleProject-images-list img.project-item-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 1s ease-in-out;
}

@media screen and (max-width: 1024px){
    .singleProject-container {
        margin-top: 10rem;
    }

    .singleProject-content {
        margin-top: 2rem;
    }

    .singleProject-main-image {
        height: 50vh;
    }

    .singleProject-info {
        padding: 1rem 0;
    }

    .singleProject-info h1 {
        font-size: 2rem;
    }

    .singleProject-info-right h1 {
        font-size: 2rem;
    }

    .singleProject-description {
        margin-top: 5rem;
    }

    .singleProject-description p {
        font-size: 3rem;
    }
    .singleProject-images-list {
        gap: 0;
    }
    
    .singleProject-images-list img {
        width: 90%;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .singleProject-container {
        margin-top: 10rem;
    }

    .singleProject-content {
        margin-top: 2rem;
    }

    .singleProject-main-image {
        height: 50vh;
    }

    .singleProject-info {
        padding: 1rem 0;
    }

    .singleProject-info h1 {
        font-size: 1.5rem;
    }

    .singleProject-info-right h1 {
        font-size: 1.5rem;
    }

    .singleProject-description {
        margin-top: 5rem;
    }

    .singleProject-description p {
        font-size: 2rem;
    }
    .singleProject-images-list {
        gap: 0;
    }
    
    .singleProject-images-list img {
        width: 90%;
        height: 100%;
    }
}

@media screen and (max-width: 320px) {
    .singleProject-container {
        margin-top: 10rem;
    }

    .singleProject-content {
        margin-top: 2rem;
    }

    .singleProject-main-image {
        height: 50vh;
    }

    .singleProject-info {
        padding: 1rem 0;
    }

    .singleProject-info h1 {
        font-size: 1.2rem;
    }

    .singleProject-info-right h1 {
        font-size: 1.2rem;
    }

    .singleProject-description {
        margin-top: 5rem;
    }

    .singleProject-description p {
        font-size: 1.5rem;
    }
    .singleProject-images-list {
        gap: 0;
    }
    
    .singleProject-images-list img {
        width: 90%;
        height: 100%;
    }
}