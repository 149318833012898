.homeContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
}

body.about-active {
    background-color: #000 ;
    transition: background-color 0.5s ease-in-out;
}

body.project-active.darkMode {
    background-color: #000;
    transition: background-color 0.5s ease-in-out;
}


body.comment-active.darkMode {
    background-color: #000;
    transition: background-color 0.5s ease-in-out;
}