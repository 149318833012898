.sectionProject-container {
    position: relative;
    padding-bottom: 5rem;
}

.sectionProject-container.darkMode.sectionProject-container-active {
    background-color: #000000 !important;
    transition: all 1s ease-in-out; 
}

.sectionProject-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sectionProject-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
}

.sectionProject-stats-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.sectionProject-stats.darkMode {
    color: #fff;
}

.sectionProject-stats.lightMode {
    color: #000;
}

.sectionProject-stats-item h1 {
    font-size: 8rem;
    font-weight: 700;
}

.sectionProject-stats-item p {
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
}

.sectionProject-list {
    margin-top: 10rem;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    width: 60%;
    z-index: 4;
}

.sectionProject-list-item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 40rem;
}

.sectionProject-list-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sectionProject-list-item:nth-child(1),
.sectionProject-list-item:nth-child(4),
.sectionProject-list-item:nth-child(7),
.sectionProject-list-item:nth-child(10) {
    grid-column: 1 / 3;
}

.sectionProject-list-item-content {
    position: absolute;
    bottom: 0%;
    right: 0;
    background-color: #181717b9;
    width: 15vw;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    transform-origin: right;
    transform: perspective(250px) rotateY(-90deg);
    transition: transform 0.5s ease;
    overflow: hidden;
}

.sectionProject-list-item:hover .sectionProject-list-item-content {
    transform: perspective(250px) rotateY(0deg);
    transition: transform 0.5s ease;
}

.sectionProject-list-item-content h1 {
    font-size: 1.8rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
    width: 80%;
}

.sectionProject-list-item-content p {
    font-size: 2.2rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    text-transform: lowercase;
    font-family: var(--secondary-font);
}

.sectionProject-button {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.sectionProject-button a {
    position: relative;
    width: 40vw;
    padding: 1.5rem 0 ;
    background-color: #a6a182;
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s ease;
    text-align: center;
    z-index: 1;
}

.sectionProject-button a::before {
    background: url(https://wpthemebooster.com/demo/themeforest/html/mrittik/assets/img/btn-texture-gray.png);
    border: 1px solid #54595f;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 100%;
    left: 10px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: -1;
}

.sectionProject-button.lightMode a{
    background-color: #181717;
    color: #fff;
} 

.sectionProject-button a:hover::before{
    left: 0;
    top: 0;
    transition: all .3s ease-in-out;
}

@media screen and (max-width: 1024px){
    .sectionProject-stats-item h1 {
        font-size: 6rem;
    }

    .sectionProject-stats-item p {
        font-size: 2rem;
    }

    .sectionProject-list-item {
        height: 30rem;
    }

    .sectionProject-list-item-content {
        width: 100%;
        padding: 0 2rem;
        justify-content: space-between;
    }

    .sectionProject-list-item-content h1 {
        font-size: 1.6rem;
    }

    .sectionProject-list-item-content p {
        font-size: 2.2rem;
        text-transform: lowercase;
    }

    .sectionProject-button a {
        width: 60vw;
    }
}

@media screen and (max-width: 768px) {
    .sectionProject-stats {
        flex-direction: column;
        width: 100%;
    }

    .sectionProject-list {
        width: 80%;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }


    .sectionProject-list-item {
        height: 30rem;
        width: 100%;
        grid-column: 1 / 3;
    }

    .sectionProject-list-item-content {
        width: 100%;
        justify-content: center;
        padding: 0 2rem;
    }

    .sectionProject-button {
        width: 100%;
    }

    .sectionProject-button a {
        width: 80%;
    }

    
    .sectionProject-list-item-content h1 {
        font-size: 2rem;
    }

    .sectionProject-list-item-content p {
        font-size: 2.2rem;
        text-transform: lowercase;
    }

}

@media screen and (max-width: 600px) {
    .sectionProject-stats-item h1 {
        font-size: 6rem;
    }

    .sectionProject-stats-item p {
        font-size: 2rem;
    }

    .sectionProject-list-item {
        height: 20rem;
    }

    .sectionProject-list-item-content h1 {
        font-size: 1.3rem;
    }

    .sectionProject-list-item-content p {
        font-size: 1.4rem;
        text-transform: lowercase;
    }
    .sectionProject-button a {
        width: 90%;
        font-size: 1.4rem;
    }


    
}

