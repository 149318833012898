.about-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 30rem;
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 15rem;
}

.about-main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.about-main-title h3 {
    font-size: 1.7rem;
    font-weight: 400;
    color: #A6A182;
    text-transform: uppercase;
    letter-spacing: .26em;
}

.about-main-title h1 {
    font-size: 3rem;
    font-weight: 200;
    width: 80%;
    text-align: center;
    line-height: 1.2;
}

.about-content-img {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-body-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10rem;
    margin: 5rem 0;
}

.about-body-text-left {
    position: relative;
}

.about-body-text-left::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    z-index: -1;
}

.about-body-text-left h1{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.8;
    text-transform: uppercase;
    text-align: right;
}

.about-body-text-right {
    width: 30%;
}

.about-body-text-right p {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.3;
}

.about-body-text-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    margin: 5rem 0;
    background-color: #000;
    width: 100%;
    height: 70vh;
}

.about-container.lightMode .about-body-text-2  {
    background-color: #fff;
}

.about-body-text-2 p {
    font-size: 6rem;
    font-weight: 200;
    line-height: 1.3;
    width: 60%;
    text-align: center;
    text-transform: lowercase;
}

.about-body-text-2 p span {
    position: relative;
    text-decoration: underline 1px #fff;
}

.about-container.lightMode .about-body-text-2 p span {
    position: relative;
    text-decoration: underline 1px #000;
}

@media screen and (max-width: 1640px){
    
    .about-body-text-2 {
        height: 80vh;
    }

    .about-body-text-2 p {
        font-size: 4rem;
    }
}

@media screen and (max-width: 1280px){
    
    .about-body-text-2 {
        height: 80vh;
    }

    .about-body-text-2 p {
        font-size: 4rem;
    }
}

@media screen and (max-width: 768px) {
    .about-container {
        margin-top: 20rem;
    }

    .about-content {
        gap: 10rem;
    }

    .about-main-title h3 {
        font-size: 1.5rem;
    }

    .about-main-title h1 {
        font-size: 2.5rem;
    }

    .about-body-text {
        gap: 5rem;
        flex-direction: column;
        align-items: center;
    }

    .about-body-text-left h1{
        text-align: center;
        font-size: 3rem;
    }

    .about-body-text-right {
        width: 80%;
    }


    .about-body-text-right p {
        font-size: 1.5rem;
    }

    .about-body-text-2 {
        height: 80vh;
    }

    .about-body-text-2 p {
        font-size: 3rem;
    }
    
}
